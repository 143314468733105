/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Project } from '../models/project';
import { Booth } from '../models/booth';
import { BrandCatalogTree } from '../models/brand-catalog-tree';
import { Brand } from '../models/brand';
import { Contract } from '../models/contract';
import { PromocodeList } from '../models/promocode-list';
import { StageFilter } from '../models/stage-filter';
import { Stage } from '../models/stage';
@Injectable({
  providedIn: 'root',
})
class ProjectService extends __BaseService {
  static readonly projectListListPath = '/project/list';
  static readonly projectListCreatePath = '/project/list';
  static readonly projectReadPath = '/project/{id}';
  static readonly projectUpdatePath = '/project/{id}';
  static readonly projectPartialUpdatePath = '/project/{id}';
  static readonly projectDeletePath = '/project/{id}';
  static readonly projectBoothListListPath = '/project/{project_id}/booth/list';
  static readonly projectBrandCatalogListPath = '/project/{project_id}/brand/catalog';
  static readonly projectBrandListListPath = '/project/{project_id}/brand/list';
  static readonly projectContractListListPath = '/project/{project_id}/contract/list';
  static readonly projectPromocodeListListListPath = '/project/{project_id}/promocode_list/list';
  static readonly projectStageFilterListPath = '/project/{project_id}/stage/filter';
  static readonly projectStageListListPath = '/project/{project_id}/stage/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProjectService.ProjectListListParams` containing the following parameters:
   *
   * - `status`: статус публикации проекта
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  projectListListResponse(params: ProjectService.ProjectListListParams): __Observable<__StrictHttpResponse<Array<Project>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Project>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectListListParams` containing the following parameters:
   *
   * - `status`: статус публикации проекта
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  projectListList(params: ProjectService.ProjectListListParams): __Observable<Array<Project>> {
    return this.projectListListResponse(params).pipe(
      __map(_r => _r.body as Array<Project>)
    );
  }

  /**
   * @param data undefined
   */
  projectListCreateResponse(data: Project): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/project/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param data undefined
   */
  projectListCreate(data: Project): __Observable<Project> {
    return this.projectListCreateResponse(data).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param id A unique integer value identifying this project.
   */
  projectReadResponse(id: number): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this project.
   */
  projectRead(id: number): __Observable<Project> {
    return this.projectReadResponse(id).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param params The `ProjectService.ProjectUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectUpdateResponse(params: ProjectService.ProjectUpdateParams): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/project/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectUpdate(params: ProjectService.ProjectUpdateParams): __Observable<Project> {
    return this.projectUpdateResponse(params).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param params The `ProjectService.ProjectPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectPartialUpdateResponse(params: ProjectService.ProjectPartialUpdateParams): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/project/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectPartialUpdate(params: ProjectService.ProjectPartialUpdateParams): __Observable<Project> {
    return this.projectPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param id A unique integer value identifying this project.
   */
  projectDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/project/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this project.
   */
  projectDelete(id: number): __Observable<null> {
    return this.projectDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ProjectService.ProjectBoothListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `contract`: contract
   *
   * - `booth_number`: booth_number
   */
  projectBoothListListResponse(params: ProjectService.ProjectBoothListListParams): __Observable<__StrictHttpResponse<Array<Booth>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.contract != null) __params = __params.set('contract', params.contract.toString());
    if (params.boothNumber != null) __params = __params.set('booth_number', params.boothNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(params.projectId))}/booth/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Booth>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectBoothListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `contract`: contract
   *
   * - `booth_number`: booth_number
   */
  projectBoothListList(params: ProjectService.ProjectBoothListListParams): __Observable<Array<Booth>> {
    return this.projectBoothListListResponse(params).pipe(
      __map(_r => _r.body as Array<Booth>)
    );
  }

  /**
   * @param params The `ProjectService.ProjectBrandCatalogListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  projectBrandCatalogListResponse(params: ProjectService.ProjectBrandCatalogListParams): __Observable<__StrictHttpResponse<Array<BrandCatalogTree>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(params.projectId))}/brand/catalog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BrandCatalogTree>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectBrandCatalogListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  projectBrandCatalogList(params: ProjectService.ProjectBrandCatalogListParams): __Observable<Array<BrandCatalogTree>> {
    return this.projectBrandCatalogListResponse(params).pipe(
      __map(_r => _r.body as Array<BrandCatalogTree>)
    );
  }

  /**
   * @param params The `ProjectService.ProjectBrandListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `project`: project
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`: name
   *
   * - `limit`: Number of results to return per page.
   *
   * - `contract`: contract
   */
  projectBrandListListResponse(params: ProjectService.ProjectBrandListListParams): __Observable<__StrictHttpResponse<Array<Brand>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.project != null) __params = __params.set('project', params.project.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.contract != null) __params = __params.set('contract', params.contract.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(params.projectId))}/brand/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Brand>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectBrandListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `project`: project
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`: name
   *
   * - `limit`: Number of results to return per page.
   *
   * - `contract`: contract
   */
  projectBrandListList(params: ProjectService.ProjectBrandListListParams): __Observable<Array<Brand>> {
    return this.projectBrandListListResponse(params).pipe(
      __map(_r => _r.body as Array<Brand>)
    );
  }

  /**
   * @param params The `ProjectService.ProjectContractListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `date`: date
   */
  projectContractListListResponse(params: ProjectService.ProjectContractListListParams): __Observable<__StrictHttpResponse<Array<Contract>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(params.projectId))}/contract/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Contract>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectContractListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `date`: date
   */
  projectContractListList(params: ProjectService.ProjectContractListListParams): __Observable<Array<Contract>> {
    return this.projectContractListListResponse(params).pipe(
      __map(_r => _r.body as Array<Contract>)
    );
  }

  /**
   * @param params The `ProjectService.ProjectPromocodeListListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `contract_number`: номер контракта
   *
   * - `contract`: contract
   */
  projectPromocodeListListListResponse(params: ProjectService.ProjectPromocodeListListListParams): __Observable<__StrictHttpResponse<Array<PromocodeList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.contractNumber != null) __params = __params.set('contract_number', params.contractNumber.toString());
    if (params.contract != null) __params = __params.set('contract', params.contract.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(params.projectId))}/promocode_list/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PromocodeList>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectPromocodeListListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `contract_number`: номер контракта
   *
   * - `contract`: contract
   */
  projectPromocodeListListList(params: ProjectService.ProjectPromocodeListListListParams): __Observable<Array<PromocodeList>> {
    return this.projectPromocodeListListListResponse(params).pipe(
      __map(_r => _r.body as Array<PromocodeList>)
    );
  }

  /**
   * @param params The `ProjectService.ProjectStageFilterListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `type`: тип этапа
   *
   * - `sum`: sum
   *
   * - `status`: статус этапа
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `due_date`: due_date
   *
   * - `contract`: идентификатор контракта
   */
  projectStageFilterListResponse(params: ProjectService.ProjectStageFilterListParams): __Observable<__StrictHttpResponse<StageFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.sum != null) __params = __params.set('sum', params.sum.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.dueDate != null) __params = __params.set('due_date', params.dueDate.toString());
    if (params.contract != null) __params = __params.set('contract', params.contract.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(params.projectId))}/stage/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StageFilter>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectStageFilterListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `type`: тип этапа
   *
   * - `sum`: sum
   *
   * - `status`: статус этапа
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `due_date`: due_date
   *
   * - `contract`: идентификатор контракта
   */
  projectStageFilterList(params: ProjectService.ProjectStageFilterListParams): __Observable<StageFilter> {
    return this.projectStageFilterListResponse(params).pipe(
      __map(_r => _r.body as StageFilter)
    );
  }

  /**
   * @param params The `ProjectService.ProjectStageListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `type`: тип этапа
   *
   * - `sum`: sum
   *
   * - `status`: статус этапа
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `due_date`: due_date
   *
   * - `contract`: идентификатор контракта
   */
  projectStageListListResponse(params: ProjectService.ProjectStageListListParams): __Observable<__StrictHttpResponse<Array<Stage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.sum != null) __params = __params.set('sum', params.sum.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.dueDate != null) __params = __params.set('due_date', params.dueDate.toString());
    if (params.contract != null) __params = __params.set('contract', params.contract.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(params.projectId))}/stage/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Stage>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectStageListListParams` containing the following parameters:
   *
   * - `project_id`:
   *
   * - `type`: тип этапа
   *
   * - `sum`: sum
   *
   * - `status`: статус этапа
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `due_date`: due_date
   *
   * - `contract`: идентификатор контракта
   */
  projectStageListList(params: ProjectService.ProjectStageListListParams): __Observable<Array<Stage>> {
    return this.projectStageListListResponse(params).pipe(
      __map(_r => _r.body as Array<Stage>)
    );
  }
}

module ProjectService {

  /**
   * Parameters for projectListList
   */
  export interface ProjectListListParams {

    /**
     * статус публикации проекта
     */
    status?: 'draft' | 'active' | 'archive';

    /**
     * Ordering
     */
    ordering?: 'date_start' | '-date_start' | 'name' | '-name';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for projectUpdate
   */
  export interface ProjectUpdateParams {

    /**
     * A unique integer value identifying this project.
     */
    id: number;
    data: Project;
  }

  /**
   * Parameters for projectPartialUpdate
   */
  export interface ProjectPartialUpdateParams {

    /**
     * A unique integer value identifying this project.
     */
    id: number;
    data: Project;
  }

  /**
   * Parameters for projectBoothListList
   */
  export interface ProjectBoothListListParams {
    projectId: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * contract
     */
    contract?: string;

    /**
     * booth_number
     */
    boothNumber?: string;
  }

  /**
   * Parameters for projectBrandCatalogList
   */
  export interface ProjectBrandCatalogListParams {
    projectId: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for projectBrandListList
   */
  export interface ProjectBrandListListParams {
    projectId: string;

    /**
     * project
     */
    project?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * name
     */
    name?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * contract
     */
    contract?: string;
  }

  /**
   * Parameters for projectContractListList
   */
  export interface ProjectContractListListParams {
    projectId: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * date
     */
    date?: 'today' | 'yesterday' | 'week' | 'month' | 'year';
  }

  /**
   * Parameters for projectPromocodeListListList
   */
  export interface ProjectPromocodeListListListParams {
    projectId: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * номер контракта
     */
    contractNumber?: string;

    /**
     * contract
     */
    contract?: string;
  }

  /**
   * Parameters for projectStageFilterList
   */
  export interface ProjectStageFilterListParams {
    projectId: string;

    /**
     * тип этапа
     */
    type?: 'invoice' | 'action' | 'bp' | 'realization';

    /**
     * sum
     */
    sum?: string;

    /**
     * статус этапа
     */
    status?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * due_date
     */
    dueDate?: string;

    /**
     * идентификатор контракта
     */
    contract?: string;
  }

  /**
   * Parameters for projectStageListList
   */
  export interface ProjectStageListListParams {
    projectId: string;

    /**
     * тип этапа
     */
    type?: 'invoice' | 'action' | 'bp' | 'realization';

    /**
     * sum
     */
    sum?: string;

    /**
     * статус этапа
     */
    status?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * due_date
     */
    dueDate?: string;

    /**
     * идентификатор контракта
     */
    contract?: string;
  }
}

export { ProjectService }
